import type { PublicRuntimeConfig } from "nuxt/schema";
import type { ProductLanguages } from "~/modules/rules";

interface SearchOptions {
  gameType: string;
  gameMode: string;
  lang: string;
}

function rulesLink(options: SearchOptions, rules: ProductLanguages, fallbackLang: string): string | undefined {
  const store = useStore();
  const productPath = store.getters["provider/productPath"];

  const { gameType, gameMode, lang } = options;

  const product = `${gameType}-${gameMode}`;
  if (!(product in rules)) return undefined;

  const langList = rules[product];

  // Test for exact match on lang-dialect
  if (langList.find((v) => v === lang)) return `${productPath}/rules/${gameType}-${gameMode}-${lang}`;

  // Retest without lang dialect
  const commonLang = lang.split("-");
  if (commonLang.length > 1 && langList.find((v) => v === commonLang[0]))
    return `${productPath}/rules/${gameType}-${gameMode}-${commonLang[0]}`;
  else if (commonLang.length === 1 && langList.find((v) => v.startsWith(`${commonLang[0]}-`))) {
    // Router will provide an alias for "en-*"" under "en". Is there a dialect we can use?
    return `${productPath}/rules/${gameType}-${gameMode}-${commonLang[0]}`;
  }

  if (langList.find((v) => v === fallbackLang)) return `${productPath}/rules/${gameType}-${gameMode}-${fallbackLang}`;

  return undefined;
}

export function useRules() {
  const { public: config } = useRuntimeConfig();
  const store = useStore();
  const { tTrim } = useLocalisation();

  const productName = computed<string>(() => {
    return tTrim(`product.${store.state.provider?.product?.productCode}`, { missingTrim: "product." });
  });

  const search: SearchOptions = {
    gameType: store.state.provider?.product?.gameType || "",
    gameMode: store.state.provider?.product?.gameMode || "",
    lang: store.state.i18n?.language || "en",
  };

  let provider = config.hlg_api_provider as keyof PublicRuntimeConfig["rules"];

  if (!(provider in config.rules)) {
    console.log("No provider specific rules, using generic provider.");
    provider = "generic";
  }

  const rulesPath = rulesLink(search, config.rules[provider] as unknown as ProductLanguages, config.default_rules_lang);
  if (rulesPath === undefined) {
    showError({
      statusCode: 404,
      message: `Rules not available for ${provider}/${search.gameType}-${search.gameMode}/${search.lang}`,
    });
  }

  return {
    productName,
    rulesPath,
  };
}
